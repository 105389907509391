import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import imgHome4Video from '../../assets/img/img-new-home-4-video.jpg'
import Fancybox from '../../containers/FancyBox';

// Helpers
import { withAffCodeURL } from '../../helpers/helpers';

// Components
import V3Button from '../buttons/V3Button';

// Images
import imgEarn from '../../assets/img/new-home-3/img-earn.png'
import imgFast from '../../assets/img/new-home-3/img-fast.png'
import imgAppre from '../../assets/img/new-home-3/img-appre.png'

class Section4 extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className='new-home-4 v3-home-4 v2-m-pt-4'>
        <Container>
            <Row>
                <Col>
                    <h2 className='poppins font-46 v2-tp-font-36 v2-m-font-28 bold dark-text-5 text-center new-home-4-heading'>Why Ovato?</h2>
                    <div className='video-wrap new-home-4-video-wrap mt-60'>
                        <Fancybox>
                            <a data-fancybox href='https://www.youtube.com/watch?v=nqmiBOejUYc'>
                                <img className='new-home-4-video' src={imgHome4Video} />
                            </a>
                        </Fancybox>
                    </div>
                </Col>
            </Row>
            <Row className='new-home-4-row-2'>
                <Col className='v2-tp-w-6 v2-m-w-12'>
                    <div className='new-home-4-box'>
                        <div className='new-home-4-box-img'>
                            <img src={imgEarn} />
                        </div>
                        <h3 className='new-home-4-box-title'>Earn on Everything</h3>
                        <p className='new-home-4-box-text-1'>Build your own community</p>
                        <p className='new-home-4-box-text-2'>Join govato and build your own network and capture multiple streams of income</p>
                    </div>
                </Col>
                <Col className='v2-tp-w-6 v2-m-w-12'>
                    <div className='new-home-4-box'>
                        <div className='new-home-4-box-img'>
                            <img src={imgFast} />
                        </div>
                        <h3 className='new-home-4-box-title'>Fast and Secure </h3>
                        <p className='new-home-4-box-text-1'>Merchant or User- 1 minute </p>
                        <p className='new-home-4-box-text-2'>No matter what you use you can be set up doing transactions in the Blockchain in about a minute</p>
                    </div>
                </Col>
                <Col className='v2-tp-w-6 v2-tp-mt-2 v2-m-w-12 v2-m-mt-0'>
                    <div className='new-home-4-box'>
                        <div className='new-home-4-box-img'>
                            <img src={imgAppre} />
                        </div>
                        <h3 className='new-home-4-box-title'>Appreciable Currency</h3>
                        <p className='new-home-4-box-text-1'>We grow together!</p>
                        <p className='new-home-4-box-text-2'>Ovato is poised to be highly appreciable. Early adopters gain exponentially</p>
                    </div>
                </Col>
            </Row>
            <Row className='align-center'>
                <Col>
                    <div className='text-center v2-mt-6 v2-m-mt-1'>
                        <V3Button text={'Get started'} href={withAffCodeURL("wallet")} />
                    </div>
                </Col>
            </Row>
        </Container>
      </section>
    );
  }
}
export default withTranslation()(Section4);