import {baseApiUrl, explorerBaseApiUrl, WALLET_URL} from "../constants/defaultValues";
import axios from "axios";
const createInstance = function () {
  const axiosInstance = axios.create({
    baseURL: baseApiUrl,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("x-access-token"),
    },
  });
  return axiosInstance;
};
const explorerInstance = function () {
  const axiosInstance = axios.create({
    baseURL: explorerBaseApiUrl,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("x-access-token"),
    },
  });
  return axiosInstance;
};
const walletInstance = function () {
  const axiosInstance = axios.create({
    baseURL: WALLET_URL,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("x-access-token"),
    },
  });
  return axiosInstance;
};
export const Service = {
  subscribeForm: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("userdata", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  ebookForm: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("/userdata", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  affiliationForm: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("/userdata", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  contactForm: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("/userdata", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  mindmapConnect: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("/userdata", payload, {
          headers: {
            "user-agent": "Chrome ",
          },
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  meetupForm: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("/userdata", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getExch: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .get("wallets/exchRate", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getCopyright: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = explorerInstance();
      axiosInstance
        .get("/copyright", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getHolders: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = explorerInstance();
      axiosInstance
        .get("/get_supply", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getNFTs: function () {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .get("/nfts")
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getNFT: function (id) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .get(`/nfts/${id}`)
        .then(function (response) {
          resolve(response.data.nfts[0]);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createPaymentIntent: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post("nfts/create_intent", payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getLeaderboard: function (period = -1) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .get(period === -1 ? `nfts/leaderboard` : `nfts/leaderboard/${period}`)
        .then(function (response) {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};
